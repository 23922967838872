import Api from './api.js'
import useCrud from './crud.js'

const END_POINT = 'email-messages'

export default {
  ...useCrud(END_POINT),

  send(data) {
    return Api.post(`${END_POINT}/send`, data)
  },
}
