<template>
  <BasePage
    padding
    title="Messaggi email"
    subtitle="gestione messaggi email"
    :breadcrumb="$route.matched"
  >
    <template #actions>
      <q-btn round color="pink" icon="mdi-plus" @click="show('create')">
        <q-tooltip>Nuovo modello di messaggio</q-tooltip>
      </q-btn>
    </template>

    <!-- detail -->
    <template v-if="detailShow">
      <BaseCard :header="detail.subject" v-if="detailEdit">
        <EmailMessageForm class="q-pa-md" :errors="errors" v-model="editable">
          <BaseBtn
            label="Salva"
            class="q-mr-md"
            @click="save()"
            :disabled="!detailDirty"
          />

          <BaseBtn label="Annulla" color="red" @click="show()" />
        </EmailMessageForm>
      </BaseCard>

      <template v-else>
        <EmailMessageCard title=" " :header="detail.subject" :email="detail">
          <template #footer>
            <BaseBtn label="Modifica" icon="mdi-pencil" @click="edit()" />

            <BaseBtn
              label="Elimina"
              icon="mdi-delete-outline"
              color="negative"
              @click="destroy()"
            />
          </template>

          <BaseModal
            confirm
            v-model="detailDestroy"
            @close="show()"
            @confirm="apiDestroy(detail.id)"
            title="Conferma richiesta"
            label="Elimina"
            ko-label="Annulla"
          >
            Rimuovere il modello
            <strong> {{ detail.subject }} </strong>?
          </BaseModal>
        </EmailMessageCard>
      </template>
    </template>

    <!-- table -->
    <EmailMessageTableList
      v-else
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="(newFilters) => (filters = newFilters)"
      @row-click="(_, row) => show(row.id)"
      @request="onRequest"
    />
  </BasePage>
</template>

<script>
import EmailMessages from '../apis/emailMessages.js'
import EmailMessageCard from '../components/email-messages/EmailMessageCard.vue'
import EmailMessageForm from '../components/email-messages/EmailMessageForm.vue'
import EmailMessageTableList from '../components/email-messages/EmailMessageTableList.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import useNotify from '../hooks/notify.js'
import { toRefs, watch } from '@vue/composition-api'
import { useActions } from '../hooks/store.js'

export default {
  name: 'EmailMessages',

  components: {
    EmailMessageCard,
    EmailMessageForm,
    EmailMessageTableList,
  },

  setup(_, ctx) {
    const { setDirty } = useActions(['setDirty'])

    const {
      close,
      errors,
      loading,
      onRequest,
      state,
      show,
      edit,
      destroy,
      apiDestroy,
      save,
    } = useApiCrudTable({
      api: EmailMessages,
      ctx,
      events: {
        destroy(response) {
          const index = state.data.findIndex(
            (item) => item.id == state.detail.id
          )
          state.data.splice(index, 1)

          success('Eliminazione completata')
          return response
        },
        store(response) {
          //skip dirty alert after store new item
          setDirty(false)
          show(response.data.id)
          return response
        },
        update(response) {
          success('Modifiche registrate')
          return response
        },
      },
      routes: {
        create: 'emailMessageCreate',
        destroy: 'emailMessageDestroy',
        edit: 'emailMessageEdit',
        index: 'emailMessages',
        show: 'emailMessageShow',
      },
      filters: {
        sort: 'subject',
        dir: 'asc',
        query: '',
      },
    })

    const { success } = useNotify()

    //breadcrumb name
    watch(
      () => ctx.root.$route.params.id,
      () => {
        if (ctx.root.$route.name == 'emailMessageShow') {
          ctx.root.$route.meta.breadcrumb.label = '...'
        }
      }
    )
    watch(
      () => state.detail,
      (value) => {
        if (ctx.root.$route.name == 'emailMessageShow') {
          ctx.root.$route.meta.breadcrumb.label = value.subject
        }
      }
    )

    return {
      apiDestroy,
      close,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      save,
      show,
      ...toRefs(state),
    }
  },
}
</script>
