<template>
  <form @submit.prevent="submit">
    <div class="q-gutter-md">
      <BaseInput
        v-model="email.subject"
        error-name="subject"
        :errors="errors"
        label="Oggetto"
      />

      <BaseInput
        class="q-mb-md"
        type="textarea"
        error-name="message"
        :errors="errors"
        v-model="email.message"
        label="Messaggio"
      />

      <BaseInput
        class="q-mb-md"
        type="textarea"
        error-name="footer"
        :errors="errors"
        v-model="email.footer"
        label="Testo a piè di pagina"
      />
    </div>

    <!-- buttons -->
    <slot />
  </form>
</template>

<script>
import useVModel from '../../hooks/vModel'

export default {
  name: 'EmailMessageForm',

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    errors: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: email } = useVModel(props.value, ctx)

    return {
      email,
    }
  },
}
</script>
