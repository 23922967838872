<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list dense class="q-pb-md">
      <template v-for="(section, name) in sections">
        <q-item :key="`label${name}`" class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue(name)" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                {{ section.label }}
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </BaseCard>
</template>

<script>
export default {
  name: 'EmailMessageCard',

  props: {
    email: Object,

    subtitle: {
      type: String,
      defaul: null,
    },

    title: {
      type: String,
      default: 'Dettagli modello messaggio',
    },
  },

  setup(props) {
    const sections = {
      subject: {
        icon: 'mdi-tag',
      },
      message: {
        icon: 'mdi-text',
      },
      footer: {
        label: 'Piè di pagina',
      },
    }

    function sectionValue(name) {
      if (sections[name].value == undefined) return props.email[name] || '-'

      return sections[name].value(props.email[name])
    }

    return {
      sections,
      sectionValue,
    }
  },
}
</script>
